import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { Feature, NestedFeature } from "../ab-resolver";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";

// #129F49
// #F89640

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    buttonText: {
        margin: "0 0 0 10px"
    }
});

class HeaderButton extends React.Component {
    render() {
        const { classes, history } = this.props;
        return (
            <Feature queryName="headerButtonLink">
                {({ feature: { text, path } = {} }) => {
                    return (
                        <Button
                            variant="raised"
                            color="secondary"
                            className={classes.button}
                            onClick={() => history.push(path)}
                        >
                            {text}
                        </Button>
                    );
                }}
            </Feature>
        );
    }
}

HeaderButton.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    //withMobileDialog(),
    withRouter,
    withStyles(styles)
)(HeaderButton);
