/**
 * Digital Optimization Group, LLC
 */
import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Feature, NestedFeature } from "../ab-resolver";
import FooterCompany from "./footer-company";
import LogoWhite from "./logo-white";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import HeaderLogo from "./logo-image";

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

const MenuItem = ({ feature, history }) => (
    <NestedFeature feature={feature}>
        {({ feature: { text, link } = {} }) => (
            <div
                style={{ padding: "2px", cursor: "pointer" }}
                onClick={() => history.push(`/${link}`)}
            >
                <Typography
                    style={{
                        padding: "2px",
                        cursor: "pointer",
                        color: "white"
                    }}
                >
                    {text}
                </Typography>
            </div>
        )}
    </NestedFeature>
);

const MenuItemComposed = withRouter(MenuItem);

const Footer = ({ feature = [], classes, history }) => (
    <div>
        <div
            style={{
                minHeight: "250px",
                backgroundColor: "#212122"
            }}
        >
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item xs={12} lg={8}>
                            <Grid container justify="left">
                                <Grid item xs={12} lg={4}>
                                    <div
                                        style={{
                                            margin: "20px 0 0 15px"
                                        }}
                                    >
                                        <div
                                            onClick={() => history.push("/")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <LogoWhite height={60} />
                                        </div>
                                        <FooterCompany />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <div
                                        style={{
                                            margin: "20px 15px 30px 15px"
                                        }}
                                    >
                                        <Feature queryName="mainMenu">
                                            {({ feature = [] }) =>
                                                feature.map((item, i) => (
                                                    <MenuItemComposed
                                                        key={i}
                                                        feature={item}
                                                    />
                                                ))
                                            }
                                        </Feature>
                                        <div
                                            style={{
                                                padding: "2px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    "https://students.tangostudionicosia.com/class-feed",
                                                    "_self",
                                                    false
                                                );
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    padding: "2px",
                                                    cursor: "pointer",
                                                    color: "white"
                                                }}
                                            >
                                                Students Only Section
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
);

export default compose(
    withStyles(styles),
    withRouter
)(Footer);
