import React from "react";
import { PropTypes } from "prop-types";
import get from "../getNested";
import { srcImage } from "../image-size";
import { compose, withProps } from "recompose";
import { Feature } from "../ab-resolver";

const LogoWhite = ({ height }) => (
    <Feature queryName="logoWhite">
        {({ feature: { imageUrl } = {} }) =>
            imageUrl ? (
                <img
                    src={srcImage((imageUrl || {}).url, height, "h")}
                    height={`${height}px`}
                />
            ) : null
        }
    </Feature>
);

LogoWhite.propTypes = {
    height: PropTypes.int
};

export default LogoWhite;
