import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { branch, renderComponent, compose } from "recompose";
import { Route, Switch, withRouter } from "react-router";
import { AnimatedSwitch } from "react-router-transition";
import { AbCloudResolverProvider } from "../ab-resolver";
import Cookies from "js-cookie";
import withRoot from "../withRoot";

import Header from "../tangostudionicosia/header";
import Home from "../tangostudionicosia/home";
import ContentPage from "../tangostudionicosia/content-page";
import Footer from "../tangostudionicosia/footer";
import FeaturePage from "../tangostudionicosia/feature-page";
import ClassFeed from "../tangostudionicosia/class-feed";
import Register from "../tangostudionicosia/register";

const {
    ABCLOUD_APP_ID,
    ALGOLIA_APP_ID,
    ALGOLIA_API_KEY,
    ALGOLIA_INDEX,
    EDITOR_API_URL = "https://tangostudionicosia.api.revenuejuice.com"
} =
    window.__APP_CONFIG__ || {};

const RootApp = ({ trackLatency }) => (
    <AbCloudResolverProvider
        appId="tangostudionicosia"
        apiUrl={EDITOR_API_URL}
        queryId={Cookies.getJSON("_vq")}
        apikey={"t7lsGfOBlm7g7sud9KRJF8kqrsxn96hV2wr72sVM"}
    >
        <div>
            <Header />
            <AnimatedSwitch
                atEnter={{ opacity: 0, foo: 0 }}
                atLeave={{ opacity: 0, foo: 2 }}
                atActive={{ opacity: 1, foo: 1 }}
                mapStyles={styles => {
                    return {
                        position: styles.foo <= 1 ? "relative" : "absolute",
                        width: "100%",
                        height: "100%",
                        opacity: styles.opacity
                    };
                }}
            >
                <Route exact path="/" component={Home} />
                <Route
                    exact
                    path="/register-for-classes-at-tango-studio-nicosia"
                    component={Register}
                />
                <Route exact path="/class-feed" component={ClassFeed} />
                <Route path="/info/:path" component={ContentPage} />
                <Route path="/:path" component={FeaturePage} />
            </AnimatedSwitch>
            <Footer />
        </div>
    </AbCloudResolverProvider>
);

// <Route path="/:section/:path" component={DetailsPage} />
// <Route path="/:path" component={DetailsPage} />
export default compose(
    withRoot,
    withRouter
)(RootApp);

// <Route path="/shopping-cart" component={Cart} />
// <Route path="/info/:path" component={InfoPages} />
// <Route path="/:videoId" component={Videos} />
