import React, { PropTypes, Component } from "react";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import RefinementList from "./RefinementList";
import { connectInfiniteHits } from "react-instantsearch/connectors";
import Waypoint from "react-waypoint";
import Helmet from "react-helmet";
import { srcImageSearch } from "../image-size";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import VideoTile from "../components/video-tile";
import Hidden from "@material-ui/core/Hidden";
import qs from "qs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InstantSearch, Configure } from "react-instantsearch/dom";
import ReactPlayer from "react-player";
import Chip from "@material-ui/core/Chip";
import Login from "./login";

const { ABCLOUD_APP_ID, ALGOLIA_APP_ID, ALGOLIA_API_KEY, ALGOLIA_INDEX } =
    window.__APP_CONFIG__ || {};

// this takes an array of classes ["mon", "wed"] and reduces them to a single filter
// to be passed into Algolia, if there are none there will be no results because
// the value will be false
const CLASSES = window.Cookies.getJSON("_lessons") || [];

const filters = CLASSES.reduce((acc, item) => {
    return acc ? `${acc} OR classId:"${item}"` : `classId:"${item}"`;
}, false);

const styles = theme => ({
    root: {
        margin: "85px 0 0 0",
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        height: "100%"
    },
    control: {
        padding: theme.spacing.unit * 2
    }
});

class Hits extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { hits, refine, hasMore, history } = this.props;
        return hits.length > 0 ? (
            <div>
                {hits.map((item, i) => (
                    <Paper style={{ margin: "20px 0 30px 0" }}>
                        <div
                            style={{
                                padding: "75% 0 0 0",
                                position: "relative"
                            }}
                        >
                            <iframe
                                src={item.videoUrl}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                }}
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                            />
                        </div>

                        <div
                            style={{
                                width: "100%",
                                backgroundColor: "#E7E7E7",
                                padding: "20px"
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: "20px",
                                    fontWeight: 600
                                }}
                            >
                                {item.date}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    color: "#5B5A5A"
                                }}
                            >
                                {item.text}
                            </Typography>
                            <div style={{ margin: "10px 0 0 0" }}>
                                {(item.tags || []).map(tag => (
                                    <Chip
                                        label={tag}
                                        style={{
                                            backgroundColor: "#969696",
                                            margin: "0 10px 0 0",
                                            color: "white"
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </Paper>
                ))}
                {hasMore ? (
                    <div>
                        <CircularProgress size={50} />
                        <Waypoint onEnter={refine} />
                    </div>
                ) : null}
            </div>
        ) : (
            <div
                style={{
                    textAlign: "center",
                    margin: "25px 0",
                    height: "300px"
                }}
            >
                No class material published yet.
            </div>
        );
    }
}

const RenderHits = withRouter(connectInfiniteHits(Hits));

class ClassFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lessonNames: {
                mon_adv: (
                    <div style={{ margin: "10px 0 0 0" }}>
                        <b>Monday 20:00-21:30</b> <br />
                        Advanced Tango Structure, Technique, & Sequences
                    </div>
                ),
                tue_beginner: (
                    <div style={{ margin: "10px 0 0 0" }}>
                        <b>Tuesday 20:00-21:30</b> <br />
                        Argentine Tango for New Beginners
                    </div>
                ),
                wed_close: (
                    <div style={{ margin: "10px 0 0 0" }}>
                        <b>Wednesday 20:00-21:30</b> <br />
                        Advanced Close Embrace
                    </div>
                ),
                wed_beginner: (
                    <div style={{ margin: "10px 0 0 0" }}>
                        <b>Wednesday 20:00-21:30</b> <br />
                        Argentine Tango for New Beginners
                    </div>
                )
            }
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={0}>
                        <Grid item xs={12} sm={12} md={8} lg={7} lg={6}>
                            {window.location.host ===
                            "students.tangostudionicosia.com" ? (
                                <InstantSearch
                                    appId={ALGOLIA_APP_ID}
                                    apiKey={ALGOLIA_API_KEY}
                                    indexName={ALGOLIA_INDEX}
                                >
                                    <Configure
                                        //query={""}
                                        attributesToRetrieve={[
                                            "date",
                                            "timestamp",
                                            "image.url",
                                            "tags",
                                            "videoUrl",
                                            "path",
                                            "text"
                                        ]}
                                        hitsPerPage={5}
                                        filters={filters}
                                    />
                                    {(CLASSES || []).length > 0 ? (
                                        <Paper style={{ padding: "20px" }}>
                                            <Typography
                                                style={{ fontSize: "16px" }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: "20px",
                                                        margin: "0 0 20px 0"
                                                    }}
                                                >
                                                    <b>
                                                        Lessons You{"'"}re
                                                        Registered For
                                                    </b>
                                                </div>
                                                {CLASSES.map(classId => (
                                                    <div>
                                                        {
                                                            this.state
                                                                .lessonNames[
                                                                classId
                                                            ]
                                                        }
                                                    </div>
                                                ))}
                                            </Typography>
                                        </Paper>
                                    ) : null}
                                    <RenderHits />{" "}
                                </InstantSearch>
                            ) : (
                                <Login />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default compose(withStyles(styles))(ClassFeed);
