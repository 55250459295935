import Remarkable from "remarkable";

export default markdown => {
    var md = new Remarkable("full", {
        html: true, // Enable HTML tags in source
        xhtmlOut: false, // Use '/' to close single tags (<br />)
        breaks: true, // Convert '\n' in paragraphs into <br>
        langPrefix: "language-", // CSS language prefix for fenced blocks
        linkify: true, // autoconvert URL-like texts to links
        linkTarget: "", // set target to open link in

        // Enable some language-neutral replacements + quotes beautification
        typographer: true,

        // Double + single quotes replacement pairs, when typographer enabled,
        // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
        quotes: "“”‘’"
    });
    return { __html: md.render(markdown) };
};
