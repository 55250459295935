import React, { Component } from "react";
import { render } from "react-dom";
import { createBrowserHistory } from "history";
import { withRouter, BrowserRouter } from "react-router-dom";
import Routes from "./pages/routes";
import registerServiceWorker from "./registerServiceWorker";
import Cookies from "js-cookie";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (
            (this.props.location || {}).pathname !==
            (prevProps.location || {}).pathname
        ) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return this.props.children;
    }
}
const ScrollToTopEnhanced = withRouter(ScrollToTop);

const rootElement = document.querySelector("#root");
if (rootElement) {
    render(
        <BrowserRouter>
            <ScrollToTopEnhanced>
                <Routes />
            </ScrollToTopEnhanced>
        </BrowserRouter>,
        rootElement
    );
}

if (module.hot) {
    module.hot.accept("./pages/routes", () => {
        render(
            <BrowserRouter>
                <ScrollToTopEnhanced>
                    <Routes />
                </ScrollToTopEnhanced>
            </BrowserRouter>,
            rootElement
        );
    });
}

registerServiceWorker();
