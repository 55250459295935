import React from "react";
import { PropTypes } from "prop-types";
import { srcImage } from "../image-size";
import { Feature } from "../ab-resolver";
const { DOMAIN } = window.__APP_CONFIG__ || {};

const HeaderLogo = ({ height, onClick }) => (
    <Feature queryName="logo">
        {({ feature: { imageUrl } = {} }) =>
            imageUrl ? (
                <img
                    src={srcImage((imageUrl || {}).url, height, "h")}
                    height={`${height}px`}
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                />
            ) : null
        }
    </Feature>
);

HeaderLogo.propTypes = {
    height: PropTypes.int
};

export default HeaderLogo;
