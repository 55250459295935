import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Feature, NestedFeature } from "../ab-resolver";
import { srcImage } from "../image-size";
import CircularProgress from "@material-ui/core/CircularProgress";
import rawMarkup from "../markdown";
import TeaserList from "./teasers-list";
import HeaderButton from "./header-button";
import withSizes from "react-sizes";

const styles = theme => ({
    root: {
        margin: "75px 0 0 0",
        flexGrow: 1
    },
    section: {
        margin: "30px 0 0 15px"
    },
    onTop: {
        zIndex: 10,
        margin: "0 0 50px 0"
    },
    headline: {
        width: "100%"
    }
});

const mapSizesToProps = ({ width, height }) => ({
    width,
    height
});

const HomeHeadline = withSizes(mapSizesToProps)(
    ({ classes, width, height }) => {
        return (
            <Feature queryName="sectionHeader" args={{ path: "/" }}>
                {({ feature: { html, image } = {} }) => {
                    return (
                        <div
                            style={{
                                backgroundImage: `url(${srcImage(
                                    (image || {}).url,
                                    // breakpoints here for smaller on mobile

                                    1800
                                )})`,
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                //backgroundAttachment: "fixed",
                                backgroundSize: "cover",
                                height: `${height - 75}px`,
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    height: "100%",
                                    padding: "10px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Typography
                                        align="center"
                                        //style={{ height: "100%" }}
                                        dangerouslySetInnerHTML={rawMarkup(
                                            html
                                        )}
                                        style={{ width: "100%" }}
                                    />
                                    <div style={{ margin: "25px 0 0 0" }}>
                                        <HeaderButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Feature>
        );
    }
);

const MenuItem = ({ feature, history }) => (
    <NestedFeature feature={feature}>
        {({ feature: { text, link } = {} }) => (
            <ul
                style={{ padding: "0 0 0 50px", cursor: "pointer" }}
                onClick={() => history.push(`/${link}`)}
            >
                <Typography
                    style={{
                        cursor: "pointer",
                        color: "#261200",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: 20,
                        fontWeight: "200",
                        lineHeight: "32px"
                    }}
                >
                    <li>
                        <a>{text}</a>
                    </li>
                </Typography>
            </ul>
        )}
    </NestedFeature>
);

const MenuItemComposed = withRouter(MenuItem);

const Home = ({ classes }) => {
    return (
        <div>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12} lg={12}>
                    <HomeHeadline classes={classes} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item xs={12} lg={8} className={classes.onTop}>
                            <TeaserList path={"/"} />

                            <HeaderButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

// <div style={{ padding: "10px" }}>
//     <InstantSearch
//         appId={"latency"}
//         apiKey={"6be0576ff61c053d5f9a3225e2a90f76"}
//         indexName={"instant_search"}
//     >
//         <SearchBox />
//     </InstantSearch>
// </div>

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles))(Home);
