import React from "react";
import { PropTypes } from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

const STRIPE_PUBLIC_KEY = window.__APP_CONFIG__.STRIPE_PUBLISHABLE_KEY;

class StripeButton extends React.Component {
    state = {
        open: false
    };

    signUp = e => {
        e.preventDefault();
        const { name, description, amount, plan } = this.props;

        this.handler = window.StripeCheckout.configure({
            key: STRIPE_PUBLIC_KEY,
            image:
                "https://img.abcloud.io/c_scale,h_60,fl_progressive/v1496395741/whkutrzotxqgprfzaijp.svg",
            locale: "auto",
            source: (source, args /*billing /shipping*/) => {
                this.setState({ open: true });
                // You can access the token ID with `token.id`.
                // POST to the server
                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", "/sign-up");

                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", "source");
                hiddenField.setAttribute(
                    "value",
                    JSON.stringify({
                        source: source.id,
                        plan,
                        email: source.owner.email
                    })
                );

                form.appendChild(hiddenField);

                document.body.appendChild(form);
                form.submit();
            }
        });
        this.handler.open({
            name: name,
            description: description,
            zipCode: true,
            amount: amount,
            panelLabel: "Complete Registration",
            allowRememberMe: false,
            zipCode: true,
            currency: "EUR"
        });
    };
    render() {
        return (
            <div>
                <Dialog fullScreen={true} open={this.state.open}>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            padding: "40px 10px 10px 10px"
                        }}
                    >
                        <div style={{ maxWidth: "400px", textAlign: "center" }}>
                            <CircularProgress />
                            <Typography
                                style={{
                                    fontSize: 20,
                                    lineHeight: "32px",
                                    maxWidth: "650px",
                                    fontWeight: 200,
                                    margin: "20px 0"
                                }}
                            >
                                <b>We are processing your payment!</b>
                                <br />
                                <br />
                            </Typography>
                        </div>
                    </div>
                </Dialog>
                <Button
                    variant="raised"
                    color="secondary"
                    onClick={this.signUp}
                >
                    Register Now - $295.00
                </Button>
            </div>
        );
    }
}

StripeButton.propTypes = {};

export default StripeButton;
