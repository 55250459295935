import React from "react";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import RefinementListMobile from "./RefinementListMobile";

import { connectRefinementList } from "react-instantsearch/connectors";

class RefinementList extends React.Component {
    constructor(props) {
        super(props);
        this.styles = {
            chip: {
                margin: 4
            },
            wrapper: {
                display: "flex",
                flexWrap: "wrap"
            }
        };
        this.state = {
            open: false,
            expanded: null
        };
    }

    handleChange = (event, expanded) => {
        this.setState({
            expanded: !this.state.expanded
        });
    };

    render() {
        return (
            <div style={{ margin: "15px 0 0 0" }}>
                <ExpansionPanel
                    elevation={0}
                    style={{ backgroundColor: "#fafafa" }}
                    expanded={this.state.expanded === true}
                    onChange={this.handleChange}
                >
                    <ExpansionPanelSummary>
                        <div style={{}}>
                            <Button>
                                <FilterListIcon
                                    style={{ margin: "0 10px 0 0" }}
                                />
                                Class Tags
                            </Button>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <RefinementListMobile
                            attribute="tags"
                            name="Tags"
                            operator="and"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Divider />
            </div>
        );
    }
}

export default RefinementList;
