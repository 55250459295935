import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    PaymentRequestButtonElement,
    IbanElement,
    IdealBankElement,
    StripeProvider,
    Elements,
    injectStripe
} from "react-stripe-elements";

const handleBlur = () => {
    console.log("[blur]");
};
const handleChange = change => {
    console.log("[change]", change);
};
const handleClick = () => {
    console.log("[click]");
};
const handleFocus = () => {
    console.log("[focus]");
};
const handleReady = () => {
    console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize: "18px",
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Kanit, sans-serif",
                "::placeholder": {
                    color: "#aab7c4"
                },
                padding: "20px"
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };
};

class _SplitForm extends React.Component {
    state = {
        wed_beginner: "wed_beginner_100",
        processing: false,
        error: null,
        cardInput: false
    };

    componentDidMount() {
        this.form.addEventListener("change", function(event) {
            window.sendMsg(
                "FORM_INPUT",
                JSON.stringify({
                    t: window.performance.now(),
                    field: event.target.name,
                    value: event.target.value
                })
            );
        });
    }

    handleChange = event => {
        this.setState({ wed_beginner: event.target.value });
    };

    cardChange = change => {
        if (this.state.cardInput === false) {
            window.sendMsg("CARD_INPUT", window.performance.now());
            this.setState({ cardInput: true });
        }
        this.setState({ complete: change.complete });
    };

    addHiddenField = (name, value) => {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", name);
        hiddenField.setAttribute("value", value);
        return hiddenField;
    };

    submitToServer = ({
        stripeToken,
        cardId,
        name,
        name2,
        email,
        classPlan,
        phone
    }) => {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", "/sign-up");

        form.appendChild(this.addHiddenField("stripeToken", stripeToken));
        form.appendChild(this.addHiddenField("cardId", cardId));
        form.appendChild(this.addHiddenField("name", name));
        form.appendChild(this.addHiddenField("name2", name2));
        form.appendChild(this.addHiddenField("email", email));
        form.appendChild(this.addHiddenField("classPlan", classPlan));
        form.appendChild(this.addHiddenField("phone", phone));

        document.body.appendChild(form);
        form.submit();
    };

    handleSubmit = ev => {
        ev.preventDefault();

        window.sendMsg("SIGN_UP_FORM_SUBMIT", window.performance.now());

        const name = ev.target.name.value;
        const name2 = ev.target.name2.value;
        const email = ev.target.email.value;
        const phone = ev.target.phone.value;
        const classPlan = this.state.wed_beginner;

        if (this.props.stripe) {
            this.setState({ processing: true, error: null });

            this.props.stripe
                .createToken()
                .then(payload => {
                    console.log("then block", payload);
                    if (payload.error) {
                        window.sendMsg("CARD_ERROR", payload.error.message);

                        this.setState({
                            error: payload.error.message,
                            processing: false
                        });
                    } else {
                        window.sendMsg(
                            "CARD_TOKEN_SUCCESS",
                            window.performance.now()
                        );

                        this.submitToServer({
                            stripeToken: ((payload || {}).token || {}).id,
                            cardId: (((payload || {}).token || {}).card || {})
                                .id,
                            name,
                            name2,
                            email,
                            classPlan,
                            phone
                        });
                    }
                })
                .catch(err => {
                    window.sendMsg("CARD_ERROR", err.message);
                    console.log("err", err);
                    this.setState({ error: err.message, processing: false });
                });
        } else {
            this.setState({
                error:
                    "Sorry there was an error. Please refresh the page and try again. Contact us at 99-326-704 if you keep seeing this message."
            });

            console.log("Stripe.js hasn't loaded yet.");
        }
    };
    render() {
        return (
            <Typography>
                <form
                    onSubmit={this.handleSubmit}
                    ref={input => (this.form = input)}
                >
                    <label>
                        Your Full Name
                        <input
                            className="StripeElement"
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            required
                        />
                    </label>
                    <label>
                        Your Partner{"'"}s Full Name (please include, even if
                        they are paying separately)
                        <input
                            className="StripeElement"
                            name="name2"
                            type="text"
                            placeholder="Partner's Name"
                            required
                        />
                    </label>
                    <label>
                        Your Email Address (for payment confirmation & site
                        login)
                        <input
                            className="StripeElement"
                            name="email"
                            type="email"
                            placeholder="name@example.com"
                            required
                        />
                    </label>
                    <label>
                        Your Phone (if we need to contact you)
                        <input
                            className="StripeElement"
                            name="phone"
                            type="phone"
                            placeholder="99-326-704"
                            required
                        />
                    </label>
                    <label>
                        Select registration type (single or couple)
                        <RadioGroup
                            name="membership"
                            value={this.state.wed_beginner}
                            onChange={this.handleChange}
                            style={{ margin: "0 0 15px 0" }}
                        >
                            <FormControlLabel
                                value="wed_beginner_50"
                                control={<Radio />}
                                label="€50,00 EUR per month - I will pay for myself (partner paying separately)"
                            />
                            <FormControlLabel
                                value="wed_beginner_100"
                                control={<Radio />}
                                label="€100,00 EUR per month - I will pay for my partner and I (one account)"
                            />
                        </RadioGroup>
                    </label>
                    <label>
                        Payment details (secure payments with Stripe)
                        <CardElement
                            onBlur={handleBlur}
                            onChange={this.cardChange}
                            onFocus={handleFocus}
                            onReady={handleReady}
                            {...createOptions(this.props.fontSize)}
                        />
                    </label>
                    <div style={{ maxWidth: "500px" }}>
                        When you click "Complete Registration" your credit card
                        will automatically be charged each month for the class{" "}
                        <b>Argentine Tango for New Beginners</b> at Tango Studio
                        Nicosia. You will also receive access to video reviews
                        from each class (available by logging into this site).
                        You are free to cancel at anytime, just give us a call
                        at <a href="tel:+357-99-326-704">99-326-704</a>.
                    </div>

                    {this.state.error ? (
                        <Typography
                            style={{
                                fontSize: "18px",
                                color: "red",
                                margin: "10px 0 0 0",
                                border: "1px solid red",
                                padding: "10px",
                                maxWidth: "500px"
                            }}
                        >
                            {this.state.error}
                        </Typography>
                    ) : null}

                    <Button
                        variant="raised"
                        color="secondary"
                        type="submit"
                        style={{ margin: "15px 0 0 0" }}
                        disabled={!this.state.complete || this.state.processing}
                    >
                        {this.state.processing ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center"
                                }}
                            >
                                <CircularProgress size={20} />
                                <div style={{ margin: "0 0 0 10px" }}>
                                    {" "}
                                    Processing, please wait...
                                </div>
                            </div>
                        ) : (
                            <span>Register Now</span>
                        )}
                    </Button>
                </form>
            </Typography>
        );
    }
}
const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
    render() {
        return (
            <div className="Checkout">
                <Elements>
                    <SplitForm fontSize="18px" />
                </Elements>
            </div>
        );
    }
}
const STRIPE_PUBLIC_KEY = window.__APP_CONFIG__.STRIPE_PUBLISHABLE_KEY;

const App = () => {
    return (
        <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
            <Checkout />
        </StripeProvider>
    );
};

export default App;
