import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Feature, NestedFeature } from "../ab-resolver";
import { srcImage } from "../image-size";
import CircularProgress from "@material-ui/core/CircularProgress";
import rawMarkup from "../markdown";
import TeaserList from "./teasers-list";
import HeaderButton from "./header-button";
import withSizes from "react-sizes";
import StripeButton from "./stripe-button";
import SignUpForm from "./sign-up-form";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/Delete";
import NavigationIcon from "@material-ui/icons/Navigation";

const styles = theme => ({
    root: {
        margin: "0 0 0 0",
        flexGrow: 1
    },
    section: {
        margin: "30px 0 0 15px"
    },
    onTop: {
        zIndex: 10,
        margin: "0 0 50px 0"
    },
    headline: {
        width: "100%"
    }
});

const mapSizesToProps = ({ width, height }) => ({
    width,
    height
});

const HomeHeadline = withSizes(mapSizesToProps)(
    ({ classes, width, height, path }) => {
        return (
            <Feature queryName="sectionHeader" args={{ path }}>
                {({ feature: { html, image } = {} }) => {
                    return (
                        <div
                            style={{
                                backgroundImage: `url(${srcImage(
                                    (image || {}).url,
                                    1800
                                )})`,
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                //backgroundAttachment: "fixed",
                                backgroundSize: "cover",
                                height: `${height - 85}px`,
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    height: "100%",
                                    padding: "10px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Typography
                                        align="center"
                                        //style={{ height: "100%" }}
                                        dangerouslySetInnerHTML={rawMarkup(
                                            html
                                        )}
                                        style={{ width: "100%" }}
                                    />
                                    {/*<div style={{ margin: "25px 0 0 0" }}>
                                        <Button variant="fab">
                                            <NavigationIcon
                                                style={{
                                                    transform: "rotate(180deg)"
                                                }}
                                            />
                                        </Button>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Feature>
        );
    }
);

const Home = ({
    classes,
    match: {
        params: { path, section }
    }
}) => {
    return (
        <div>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12} lg={12}>
                    <HomeHeadline classes={classes} path={path} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item xs={12} lg={8} className={classes.onTop}>
                            <TeaserList path={path} />
                            <div style={{ margin: "0 10px 0 10px" }}>
                                <SignUpForm />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

// <StripeButton
//     name={"Register Now - 50 EUR month"}
//     description={
//         "Argentine Tango for New Beginners"
//     }
//     amount={10000}
//     plan={"test"}
//     currency="EUR"
// />
// <div style={{ padding: "10px" }}>
//     <InstantSearch
//         appId={"latency"}
//         apiKey={"6be0576ff61c053d5f9a3225e2a90f76"}
//         indexName={"instant_search"}
//     >
//         <SearchBox />
//     </InstantSearch>
// </div>

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withRouter
)(Home);
