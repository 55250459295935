/**
 * Digital Optimization Group, LLC
 */
import React from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { compose } from "recompose";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

class FocusedTextField extends React.Component {
    componentDidMount() {
        this.emailField.focus();
    }
    render() {
        return (
            <TextField
                label="Email Address"
                fullWidth
                inputRef={field => (this.emailField = field)}
                onChange={this.props.updateEmail}
            />
        );
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false
        };
    }

    login = () => {
        //this.setState({ open: false });
        console.log("LOGIN", this.state.email);
        this.setState({ processing: true });

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", "/send-login-link");

        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "email");
        hiddenField.setAttribute("value", this.state.email);

        form.appendChild(hiddenField);

        document.body.appendChild(form);
        form.submit();
    };

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    padding: "20px 10px 200px 10px",
                    maxWidth: "400px"
                }}
            >
                <Typography
                    style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        fontWeight: 200,
                        margin: "20px 0",
                        width: "100%"
                    }}
                >
                    <div style={{ margin: "0 0 5px 0" }}>
                        <b>Login to the students{"'"} section </b>
                    </div>
                    <div style={{}}>
                        <FocusedTextField
                            updateEmail={e =>
                                this.setState({ email: e.target.value })
                            }
                        />
                    </div>
                </Typography>
                <Button
                    variant="raised"
                    color="secondary"
                    onClick={this.login}
                    disabled={this.state.processing}
                >
                    {this.state.processing ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignContent: "center"
                            }}
                        >
                            <CircularProgress size={20} />
                            <div style={{ margin: "0 0 0 10px" }}>
                                {" "}
                                Processing, please wait...
                            </div>
                        </div>
                    ) : (
                        <span>Login</span>
                    )}
                </Button>
                <Typography
                    style={{
                        fontSize: "14px",
                        lineHeight: "17px",
                        margin: "10px 0 0 0"
                    }}
                >
                    A link with a login code will be sent to the email used to
                    create your account, please follow that link to login.
                </Typography>
            </div>
        );
    }
}

export default Login;
