import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Feature, NestedFeature } from "../ab-resolver";
import { blurImage, srcImage } from "../image-size";
import rawMarkup from "../markdown";
import withSizes from "react-sizes";
import Button from "material-ui/Button";
import ReactPlayer from "react-player";

const styles = theme => ({
    root: {
        margin: "0 0 0 0",
        flexGrow: 1,
        backgroundColor: "white"
    },
    section: {
        margin: "30px 0 0 15px"
    },
    onTop: {
        zIndex: 10,
        margin: "0 0 50px 0"
    },
    headline: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            color: "#261200",
            fontWeight: 400,
            lineHeight: "56px",
            fontSize: "60px",
            maxWidth: "800px"
        },
        [theme.breakpoints.down("md")]: {
            color: "#261200",
            fontWeight: 400,
            lineHeight: "42px",
            fontSize: "48px"
        },
        [theme.breakpoints.down("sm")]: {
            color: "#261200",
            fontWeight: 400,
            lineHeight: "38px",
            fontSize: "34px"
        }
    }
});

const TeaserList = withSizes(({ width }) => ({ width }))(
    ({ width, path, history, classes }) => {
        return (
            <Feature
                queryName="teaserList"
                args={{
                    path
                }}
            >
                {({ feature = [] }) => {
                    return (Array.isArray(feature) ? feature : []).map(
                        (item = {}, index) => (
                            <NestedFeature feature={item} key={index}>
                                {({
                                    feature: { image, headline, body } = {}
                                }) => {
                                    return (
                                        <div
                                            style={{
                                                padding: "40px 10px 0px 10px",
                                                flex: 1,
                                                maxWidth: "800px"
                                            }}
                                        >
                                            <Typography
                                                className={classes.headline}
                                            >
                                                {headline}
                                            </Typography>
                                            <div
                                                style={{
                                                    fontFamily:
                                                        "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: 20,
                                                    fontWeight: "200",
                                                    lineHeight: "32px"
                                                }}
                                                dangerouslySetInnerHTML={rawMarkup(
                                                    body
                                                )}
                                            />
                                        </div>
                                    );
                                    // return (
                                    //     <div
                                    //         style={{
                                    //             margin: "50px 0",
                                    //             display: "flex",
                                    //             flexWrap: "wrap"
                                    //         }}
                                    //     >
                                    //         <div
                                    //             style={{
                                    //                 ...(width > 959
                                    //                     ? {
                                    //                           order:
                                    //                               (index % 2) *
                                    //                               2
                                    //                       }
                                    //                     : { order: 0 }),
                                    //                 fontSize: "18px"
                                    //             }}
                                    //         >
                                    //             <img
                                    //                 src={srcImage(
                                    //                     image.url,
                                    //                     350,
                                    //                     "h"
                                    //                 )}
                                    //                 style={{
                                    //                     maxWidth: width,
                                    //                     ...(width < 650
                                    //                         ? {}
                                    //                         : {
                                    //                               height: `${350}px`
                                    //                           }),
                                    //                     padding: "10px"
                                    //                 }}
                                    //             />
                                    //         </div>
                                    //         <div
                                    //             style={{
                                    //                 padding: "0 10px 0 10px",
                                    //                 flex: 1,
                                    //                 minWidth: "300px"
                                    //             }}
                                    //         >
                                    //             <Typography
                                    //                 variant="display3"
                                    //                 style={{
                                    //                     color: "#404040",
                                    //                     fontWeight: "400",
                                    //                     lineHeight: "56px"
                                    //                 }}
                                    //             >
                                    //                 {headline}
                                    //             </Typography>
                                    //             <div
                                    //                 style={{
                                    //                     fontFamily:
                                    //                         "Roboto, Helvetica, Arial, sans-serif",
                                    //                     fontSize: 20,
                                    //                     fontWeight: "200",
                                    //                     lineHeight: "32px"
                                    //                 }}
                                    //                 dangerouslySetInnerHTML={rawMarkup(
                                    //                     body
                                    //                 )}
                                    //             />
                                    //         </div>
                                    //     </div>
                                    // );
                                }}
                            </NestedFeature>
                        )
                    );
                }}
            </Feature>
        );
    }
);

TeaserList.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired
};

export default compose(
    withStyles(styles),
    withRouter
)(TeaserList);
