import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { Feature, NestedFeature } from "../ab-resolver";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing.unit
    }
});

class DrowDownMenu extends React.Component {
    state = {
        anchorEl: null,
        open: false
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.target, open: true });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null, open: false });
    };

    render() {
        const { handleClose } = this.props;
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? "simple-popper" : null;

        return (
            <div
                onMouseEnter={this.handlePopoverOpen}
                onMouseLeave={this.handlePopoverClose}
                onClick={this.handlePopoverClose}
                style={{
                    zIndex: "99999",
                    cursor: "pointer",
                    margin: "0 20px 0 0",
                    padding: "10px"
                }}
            >
                <Typography
                    style={{
                        fontWeight: "900",
                        fontSize: "18px",
                        //letterSpacing: "1px",
                        color: "#261200"
                    }}
                    onClick={() => {
                        this.props.handleClose(`/${this.props.link}`);
                    }}
                >
                    {this.props.displayName}
                </Typography>

                <Popper
                    id={id}
                    open={open}
                    transition
                    anchorEl={anchorEl}
                    style={{ zIndex: "9990" }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div>
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderLeft: "15px solid transparent",
                                        borderRight: "15px solid transparent",
                                        borderBottom: "15px solid #f9ab66",
                                        margin: "auto"
                                        // position: "absolute",
                                        // width: "40px",
                                        // height: "40px",
                                        // //zIndex: "999999",
                                        // backgroundColor: "#f9ab66",
                                        // boxShadow:
                                        //     "1px 1px 5px 1px rgba(0,0,0,0.4)",
                                        // transform: "rotate(45deg)",
                                        // top: "-40px",
                                        // margin: "auto"
                                    }}
                                />
                                <Paper
                                    style={{
                                        backgroundColor: "#FEFDF1",
                                        padding: "10px 0 10px 0"
                                    }}
                                >
                                    <NestedFeature
                                        feature={this.props.subMenuList}
                                        //manualRef={true}
                                    >
                                        {({ feature = [] }) => {
                                            return feature.map((item, i) => {
                                                return (
                                                    <NestedFeature
                                                        feature={item}
                                                        key={i}
                                                        manualRef={true}
                                                    >
                                                        {({
                                                            feature: {
                                                                text,
                                                                link,
                                                                ref
                                                            } = {}
                                                        }) => {
                                                            return (
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        this.props.handleClose(
                                                                            `/${link}`
                                                                        );
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight:
                                                                                "200",
                                                                            fontSize:
                                                                                "18px",
                                                                            letterSpacing:
                                                                                "0px",
                                                                            padding:
                                                                                "10px"
                                                                        }}
                                                                    >
                                                                        {text}
                                                                    </Typography>
                                                                </MenuItem>
                                                            );
                                                        }}
                                                    </NestedFeature>
                                                );
                                            });
                                        }}
                                    </NestedFeature>
                                </Paper>
                            </div>
                        </Fade>
                    )}
                </Popper>
            </div>
        );
    }
}

class HeaderMenuLarge extends React.Component {
    render() {
        return (
            <Feature queryName="mainMenuDesktop" manualRef={true}>
                {({ feature = [] }) =>
                    feature.map((item, i) => {
                        return (
                            <NestedFeature
                                feature={item}
                                key={i}
                                manualRef={true}
                            >
                                {({
                                    feature: {
                                        displayName,
                                        subMenuList,
                                        link
                                    } = {}
                                }) => {
                                    return (
                                        <DrowDownMenu
                                            displayName={displayName}
                                            subMenuList={subMenuList}
                                            handleClose={this.props.handleClose}
                                            link={link}
                                        />
                                    );
                                }}
                            </NestedFeature>
                        );
                    })
                }
            </Feature>
        );
    }
}

HeaderMenuLarge.propTypes = {
    handleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(HeaderMenuLarge);
