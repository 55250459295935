import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { Feature, NestedFeature } from "../ab-resolver";
import rawMarkup from "../markdown";
import TeaserList from "./teasers-list";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { srcImage } from "../image-size";
import HeaderButton from "./header-button";

const slidesStyle = { width: "100%", objectFit: "cover", height: "50vh" };

const SliderImages = ({}) => <div />;

const styles = theme => ({
    root: {
        margin: "85px 0 0 0",
        flexGrow: 1,
        minHeight: "550px"
    },
    section: {
        margin: "30px 0 0 15px"
    },
    headline: {
        [theme.breakpoints.up("md")]: {
            color: "#261200",
            fontWeight: 900,
            lineHeight: "56px",
            fontSize: "60px",
            maxWidth: "800px"
        },
        [theme.breakpoints.down("md")]: {
            color: "#261200",
            fontWeight: 900,
            lineHeight: "42px",
            fontSize: "48px"
        },
        [theme.breakpoints.down("sm")]: {
            color: "#261200",
            fontWeight: 900,
            lineHeight: "38px",
            fontSize: "34px"
        }
    }
});

const InfoPage = ({
    match: {
        params: { path, section }
    },
    classes
}) => {
    return (
        <div className={classes.root}>
            <Feature
                queryName="contentPages"
                args={{
                    path: `info/${path}`
                }}
            >
                {({ feature: { body, headline } = {} }) => (
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            <Grid item xs={12} lg={8}>
                                <div style={{ margin: "0 0 0 0" }}>
                                    <div
                                        style={{
                                            margin: "30px"
                                        }}
                                    >
                                        <Typography
                                            className={classes.headline}
                                        >
                                            {headline}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: 20,
                                                lineHeight: "32px",
                                                maxWidth: "650px",
                                                fontWeight: 200,
                                                color: "#261200"
                                            }}
                                            dangerouslySetInnerHTML={rawMarkup(
                                                body
                                            )}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Feature>
        </div>
    );
};

InfoPage.propTypes = {
    feature: PropTypes.shape({})
};

export default compose(
    withRouter,
    withStyles(styles)
)(InfoPage);
