import React from "react";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import { connectRefinementList } from "react-instantsearch/connectors";

class RefinementList extends React.Component {
    constructor(props) {
        super(props);
        this.styles = {
            chip: {
                margin: 4
            },
            wrapper: {
                display: "flex",
                flexWrap: "wrap"
            }
        };
        this.state = {
            open: false,
            expanded: null
        };
    }

    handleChange = (event, expanded) => {
        this.setState({
            expanded: !this.state.expanded
        });
    };

    addFeaturesToList(tagName) {
        this.props.refine([tagName, ...this.props.currentRefinement]);
        setTimeout(
            () =>
                this.setState({
                    expanded: false
                }),
            10
        );
    }

    handleRequestDelete = key => {
        this.props.refine(
            this.props.currentRefinement.filter(item => item !== key)
        );
    };

    renderChip(data) {
        return (
            <Chip
                label={data.label}
                onDelete={() => this.handleRequestDelete(data.key)}
                style={this.styles.chip}
            />
        );
    }

    render() {
        const chipData = this.props.currentRefinement.map(item => ({
            key: item,
            label: item
        }));

        // also has a count
        var featuresList = this.props.items
            .map(item => item.label)
            .filter(item => {
                // if the item is already selected then filter it from our list
                return !chipData.find(i => i.key == item);
            })
            .map((label, i) => (
                <ListItem
                    key={i}
                    onClick={this.addFeaturesToList.bind(this, label)}
                    style={{ cursor: "pointer" }}
                >
                    <Typography>{label}</Typography>
                </ListItem>
            ));

        return (
            <div>
                {this.props.items.length > 0 ? (
                    <div>
                        <Typography>
                            <b>Filter by {this.props.name}</b>
                        </Typography>

                        {chipData.length > 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    margin: "10px 15px 0 15px",
                                    cursor: "pointer",
                                    // if no items in list give it set height
                                    height: chipData[0] ? "auto" : "40px"
                                }}
                                //onClick={this.handleTouchTap.bind(this)}
                            >
                                {chipData.map(this.renderChip, this)}
                            </div>
                        ) : null}
                        <List
                            disableAutoFocus={true}
                            style={{ padding: "5px" }}
                        >
                            {featuresList}
                        </List>
                    </div>
                ) : (
                    <div style={{ width: "250px" }} />
                )}
            </div>
        );
    }
}

export default connectRefinementList(RefinementList);
