import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from '@material-ui/core/Checkbox';
import Divider from "@material-ui/core/Divider";
import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    PaymentRequestButtonElement,
    IbanElement,
    IdealBankElement,
    StripeProvider,
    Elements,
    injectStripe
} from "react-stripe-elements";
import { Feature, NestedFeature } from "../ab-resolver";

const handleBlur = () => {
    console.log("[blur]");
};
const handleChange = change => {
    console.log("[change]", change);
};
const handleClick = () => {
    console.log("[click]");
};
const handleFocus = () => {
    console.log("[focus]");
};
const handleReady = () => {
    console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize: "18px",
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Kanit, sans-serif",
                "::placeholder": {
                    color: "#aab7c4"
                },
                padding: "20px"
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };
};

class _SplitForm extends React.Component {
    state = {
        processing: false,
        mon_adv: "0",
        tue_beginner: "0",
        wed_close: "0",
        agree: false,
        error: null
    };

    handleChange = (id, event) => {
        this.setState({ [id]: event.target.value });
    };

    cardChange = change => {
        this.setState({ complete: change.complete });
    };

    submitToServer = payload => {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", "/sign-up");

        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "payload");
        hiddenField.setAttribute("value", JSON.stringify(payload));

        form.appendChild(hiddenField);

        document.body.appendChild(form);
        form.submit();
    };

    handleSubmit = ev => {
        ev.preventDefault();

        const name = ev.target.name.value;
        const partner = ev.target.name2.value;
        const email = ev.target.email.value;

        const membership = [
            {
                selected: this.state.mon_adv !== "0",
                value: this.state.mon_adv,
                classId: "mon_adv"
            },
            {
                selected: this.state.tue_beginner !== "0",
                value: this.state.tue_beginner,
                classId: "tue_beginner"
            },
            {
                selected: this.state.wed_close !== "0",
                value: this.state.wed_close,
                classId: "wed_close"
            }
        ].filter(item => item.selected);

        const comment = ev.target.comment.value;

        if (this.props.stripe) {
            this.setState({ processing: true, error: null });

            this.props.stripe.createToken().then(payload => {
                if(payload.error){
                    this.setState({error: payload.error.message, processing: false})
                }else{
                    this.submitToServer({
                        token: ((payload || {}).token || {}).id,
                        cardId: (((payload || {}).token || {}).card || {}).id,
                        name,
                        partner,
                        email,
                        membership,
                        comment
                    });
                }
            })
            .catch(err => {
                console.log("err", err)
                this.setState({error: err.message, processing: false})
            });
        } else {
            this.setState({error: "Sorry there was an error. Please refresh the page and try again. Contact us at 99-326-704 if you keep seeing this message." });

            console.log("Stripe.js hasn't loaded yet.");
        }
    };
    render() {
        console.log(this.state);
        return (
            <Typography>
                <div style={{ fontSize: "18px", lineHeight: "24px" }}>
                    <b>Mondays 20:00-21:30</b>
                    <br />
                    Advanced Tango Structure, Technique, & Sequences
                </div>
                <Divider
                    style={{ maxWidth: "500px", margin: "10px 0 10px 0" }}
                />
                <RadioGroup
                    name="mon_adv"
                    value={this.state.mon_adv}
                    onChange={this.handleChange.bind(null, "mon_adv")}
                    style={{ margin: "0 0 30px 0" }}
                >
                    <FormControlLabel
                        value="50"
                        control={<Radio />}
                        label="€50,00 EUR per month - I will pay for myself (partner paying separately)"
                    />
                    <FormControlLabel
                        value="100"
                        control={<Radio />}
                        label="€100,00 EUR per month - I will pay for my partner and I (one account)"
                    />
                    <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Not taking this class"
                    />
                </RadioGroup>
                <div style={{ fontSize: "18px", lineHeight: "24px" }}>
                    <b>Tuesdays 20:00-21:30</b>
                    <br />
                    Argentine Tango for New Beginners
                </div>
                <Divider
                    style={{ maxWidth: "500px", margin: "10px 0 10px 0" }}
                />
                <RadioGroup
                    name="tue_beginner"
                    value={this.state.tue_beginner}
                    onChange={this.handleChange.bind(null, "tue_beginner")}
                    style={{ margin: "0 0 30px 0" }}
                >
                    <FormControlLabel
                        value="50"
                        control={<Radio />}
                        label="€50,00 EUR per month - I will pay for myself (partner paying separately)"
                    />
                    <FormControlLabel
                        value="100"
                        control={<Radio />}
                        label="€100,00 EUR per month - I will pay for my partner and I (one account)"
                    />
                    <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Not taking this class"
                    />
                </RadioGroup>

                <div style={{ fontSize: "18px", lineHeight: "24px" }}>
                    <b>Wednesdays 20:00-21:30</b>
                    <br />
                    Advanced Close Embrace
                </div>
                <Divider
                    style={{ maxWidth: "500px", margin: "10px 0 10px 0" }}
                />
                <RadioGroup
                    name="wed_close"
                    value={this.state.wed_close}
                    onChange={this.handleChange.bind(null, "wed_close")}
                    style={{ margin: "0 0 30px 0" }}
                >
                    <FormControlLabel
                        value="50"
                        control={<Radio />}
                        label="€50,00 EUR per month - I will pay for myself (partner paying separately)"
                    />
                    <FormControlLabel
                        value="100"
                        control={<Radio />}
                        label="€100,00 EUR per month - I will pay for my partner and I (one account)"
                    />
                    <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Not taking this class"
                    />
                </RadioGroup>

                <div style={{ fontSize: "18px", lineHeight: "24px" }}>
                    <b>Your Registration Details & Payment</b>
                    <br />
                    Please fill out all fields below
                </div>
                <Divider
                    style={{ maxWidth: "500px", margin: "10px 0 20px 0" }}
                />
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Your Full Name
                        <input
                            className="StripeElement"
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            required
                        />
                    </label>
                    <label>
                        Your Partner{"'"}s Full Name (please include, even if
                        they are paying separately)
                        <input
                            className="StripeElement"
                            name="name2"
                            type="text"
                            placeholder="Partner's Name"
                            required
                        />
                    </label>
                    <label>
                        Your Email Address (for payment confirmation & site
                        login)
                        <input
                            className="StripeElement"
                            name="email"
                            type="email"
                            placeholder="name@example.com"
                            required
                        />
                    </label>
                    <label>
                        Payment details (secure payments with Stripe)
                        <CardElement
                            onBlur={handleBlur}
                            onChange={this.cardChange}
                            onFocus={handleFocus}
                            onReady={handleReady}
                            {...createOptions(this.props.fontSize)}
                        />
                    </label>
                    <label>
                        Comment (optional)
                        <input
                            className="StripeElement"
                            name="comment"
                            type="text"
                        />
                    </label>

                    {parseInt(this.state.mon_adv) +
                        parseInt(this.state.tue_beginner) +
                        parseInt(this.state.wed_close) >
                    0 ? (
                        <div
                            style={{ maxWidth: "500px", margin: "0 0 20px 0" }}
                        >
                            <div style={{ margin: "15px 0 0 0" }}>

                                <b>You are registering for</b>
                            </div>
                            <div style={{ margin: "15px 0 0 0" }}>
                                {this.state.mon_adv > 0 ? (
                                    <div>
                                        Advanced Tango Structure, Technique, &
                                        Sequences on Mondays 20:00-21:30
                                    </div>
                                ) : null}
                                {this.state.tue_beginner > 0 ? (
                                    <div>
                                        Argentine Tango for New Beginners on
                                        Tuesdays 20:00-21:30
                                    </div>
                                ) : null}
                                {this.state.wed_close > 0 ? (
                                    <div>
                                        Advanced Close Embrace on Wednesdays
                                        20:00-21:30
                                    </div>
                                ) : null}
                            </div>
                            <div style={{ margin: "15px 0 0 0" }}>
                                Your credit card will be charged €{parseInt(
                                    this.state.mon_adv
                                ) +
                                    parseInt(this.state.tue_beginner) +
                                    parseInt(this.state.wed_close)},00 EUR per
                                month.
                            </div>
                            <div style={{ margin: "15px 0 0 0" }}>
                                You will also receive access to video reviews
                                from each class (available by logging into this
                                site). You are free to cancel at anytime, just
                                give us a call at{" "}
                                <a href="tel:+357-99-326-704">99-326-704</a>.
                            </div>
                        </div>
                    ) : null}


                    <Button
                        variant="raised"
                        color="secondary"
                        type="submit"
                        style={{ margin: "15px 0 0 0" }}
                        disabled={!this.state.complete || this.state.processing}
                    >
                        {this.state.processing ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center"
                                }}
                            >
                                <CircularProgress size={20} />
                                <div style={{ margin: "0 0 0 10px" }}>
                                    {" "}
                                    Processing, please wait...
                                </div>
                            </div>
                        ) : (
                            <span>Register Now</span>
                        )}
                    </Button>
                </form>
            </Typography>
        );
    }
}
const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
    render() {
        return (
            <div className="Checkout">
                <Elements>
                    <SplitForm fontSize="18px" />
                </Elements>
            </div>
        );
    }
}
const STRIPE_PUBLIC_KEY = window.__APP_CONFIG__.STRIPE_PUBLISHABLE_KEY;

const App = () => {
    return (
        <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
            <Checkout />
        </StripeProvider>
    );
};

export default App;
