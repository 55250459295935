import React from "react";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import HeaderLogo from "./logo-image";
import HeaderMenu from "./header-menu";
import IconButton from "@material-ui/core/IconButton";
import HeaderButton from "./header-button";
import Hidden from "@material-ui/core/Hidden";
import HeaderMenuLarge from "./header-menu-large";
import Typography from "@material-ui/core/Typography";
import { Feature, NestedFeature } from "../ab-resolver";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    handleToggle = () => {
        this.setState({ open: !this.state.open });
    };

    handleClose = path => {
        this.setState({ open: false });
        this.props.history.push(path);
    };

    render() {
        return (
            <Paper
                style={{
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    zIndex: "999",
                    height: "75px",
                    display: "flex",
                    justifyContent: "center"
                }}
                zDepth={1}
            >
                <div
                    style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "75px",
                        //maxWidth: "1200px",
                        width: "100%"
                    }}
                >
                    <HeaderMenu
                        open={this.state.open}
                        handleClose={this.handleClose}
                        handleToggle={this.handleToggle}
                    />

                    <div
                        style={{
                            padding: "0px 0 0 20px",
                            flex: 1
                        }}
                    >
                        <HeaderLogo
                            height={50}
                            onClick={() => this.props.history.push("/")}
                        />
                    </div>

                    <div
                        style={{
                            margin: "0 15px 0 0",
                            cursor: "pointer",
                            justifyContent: "flex-end",
                            flexWrap: "wrap",
                            display: "flex"
                        }}
                    >
                        <Hidden mdDown>
                            <Feature queryName="wideMenu" manualRef={true}>
                                {({ feature = [] }) =>
                                    feature.map((item, i) => (
                                        <NestedFeature feature={item} key={i}>
                                            {({
                                                feature: { text, link } = {}
                                            }) => (
                                                <Typography
                                                    style={{
                                                        padding:
                                                            "11px 30px 0 20px",
                                                        flex: 1,
                                                        fontWeight: 200,
                                                        fontSize: "18px"
                                                    }}
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            `/${link}`
                                                        )
                                                    }
                                                >
                                                    {text}
                                                </Typography>
                                            )}
                                        </NestedFeature>
                                    ))
                                }
                            </Feature>
                        </Hidden>
                        <div
                            style={{ padding: "0 0 0 10px" }}
                            onClick={this.handleToggle}
                        >
                            <IconButton>
                                <MenuIcon
                                    style={{
                                        cursor: "pointer",
                                        color: "#4c2500"
                                    }}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withRouter(Header);
