/**
 * Digital Optimization Group, LLC
 */
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Feature } from "../ab-resolver";
import rawMarkup from "../markdown";

const FooterCompany = () => (
    <Feature queryName="footerCompany">
        {({ feature: { html = "" } = {} }) => (
            <div
                style={{
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: 14,
                    fontWeightLight: 300,
                    fontWeightRegular: 400,
                    fontWeightMedium: 500,
                    color: "white"
                }}
                dangerouslySetInnerHTML={rawMarkup(html)}
            />
        )}
    </Feature>
);

export default FooterCompany;
