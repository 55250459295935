import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import HeaderButton from "./header-button";
import { Feature, NestedFeature } from "../ab-resolver";
import HeaderLogo from "./logo-image";

const MainMenuItems = ({ handleClose }) => (
    <Feature queryName="mainMenu">
        {({ feature = [] }) =>
            feature.map((item, i) => (
                <NestedFeature feature={item} key={i}>
                    {({ feature: { text, link } = {} }) => (
                        <MenuItem
                            onClick={() => {
                                handleClose(`/${link}`);
                            }}
                            style={{
                                whiteSpace: "normal",
                                fontSize: "15px",
                                lineHeight: "14px"
                            }}
                        >
                            {text}
                        </MenuItem>
                    )}
                </NestedFeature>
            ))
        }
    </Feature>
);

const HeaderMenu = ({ handleClose, handleToggle, open }) => (
    <SwipeableDrawer
        open={open}
        onClose={handleToggle}
        ModalProps={{
            keepMounted: true // Better open performance on mobile.
        }}
        anchor="right"
    >
        <div style={{ width: "300px" }}>
            <div
                style={{ padding: "15px" }}
                onClick={() => {
                    handleClose(`/`);
                }}
            >
                <HeaderLogo height={50} />
            </div>

            <Divider style={{ minWidth: "250px" }} />

            <MainMenuItems handleClose={handleClose} />

            <MenuItem
                onClick={() => {
                    window.open(
                        "https://students.tangostudionicosia.com/class-feed",
                        "_self",
                        false
                    );
                }}
                style={{
                    whiteSpace: "normal",
                    fontSize: "15px",
                    lineHeight: "14px"
                }}
            >
                Students Only Section
            </MenuItem>

            {window.location.host === "students.tangostudionicosia.com" ? (
                <MenuItem
                    onClick={() => {
                        window.Cookies.remove("_isLoggedIn");
                        window.Cookies.remove("_lessons");
                        window.Cookies.remove("abcloudToken");
                        window.open(
                            "https://tangostudionicosia.com/",
                            "_self",
                            false
                        );
                    }}
                    style={{
                        whiteSpace: "normal",
                        fontSize: "15px",
                        lineHeight: "14px"
                    }}
                >
                    Logout
                </MenuItem>
            ) : null}

            <Divider style={{ minWidth: "250px", margin: "0 0 10px 0" }} />
            <div onClick={handleClose} style={{ margin: "0 10px 50px 10px" }}>
                <HeaderButton />
            </div>
        </div>
    </SwipeableDrawer>
);

HeaderMenu.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default HeaderMenu;
