const Resolver = require(`@revenuejuice/editor-client-react/dist/${window
    .__APP_CONFIG__.RESOLVER || "production"}`);

export const AbCloudResolverProvider = Resolver.AbCloudResolverProvider;
export const connectFeature = Resolver.connectFeature;
export const connectChild = Resolver.connectChild;
export const trackComponent = Resolver.trackComponent;
export const preCacheFeature = Resolver.preCacheFeature;
export const Feature = Resolver.Feature;
export const NestedFeature = Resolver.NestedFeature;
