import Cookies from "js-cookie";

const { IMAGE_CDN_DOMAIN } = Cookies.getJSON("CLIENT_CONFIG");

export const blurImage = (imageSrc = "") => {
    const image = (imageSrc || "").split("/");
    const res = imageSrc
        ? `${IMAGE_CDN_DOMAIN}/c_scale,e_blur:200,w_50/v1496395741/${
              image[image.length - 1]
          }`
        : null;
    return res;
};

// 210 , 118
// 246, 138

export const blurFade = (imageSrc = "") => {
    const image = (imageSrc || "").split("/");
    const res = imageSrc
        ? `${IMAGE_CDN_DOMAIN}/c_scale,e_blur:300,w_500,o_40/v1496395741/${
              image[image.length - 1]
          }`
        : null;
    return res;
};

export const srcImageSearch = (imageSrc = "", w, h) => {
    const image = (imageSrc || "").split("/");
    const res = imageSrc
        ? `${IMAGE_CDN_DOMAIN}/c_fill,w_${w},h_${h},g_faces,fl_progressive/v1496395741/${
              image[image.length - 1]
          }`
        : null;
    return res;
};

export const srcImage = (imageSrc = "", size, wh = "w", etrim) => {
    const image = (imageSrc || "").split("/");
    const res = imageSrc
        ? `${IMAGE_CDN_DOMAIN}/c_scale,${wh}_${size},fl_progressive/${
              etrim ? "e_trim/" : ""
          }v1496395741/${image[image.length - 1]}`
        : null;
    return res;
};

export const srcImageGrayscale = (imageSrc = "", size, wh = "w", etrim) => {
    const image = (imageSrc || "").split("/");
    const res = imageSrc
        ? `${IMAGE_CDN_DOMAIN}/c_scale,${wh}_${size},fl_progressive/${
              etrim ? "e_trim,e_grayscale/" : "e_grayscale/"
          }v1496395741/${image[image.length - 1]}`
        : null;
    return res;
};

export const getTargetSize = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const browserWidth = () => {
    // if (self.innerWidth) {
    //     return self.innerWidth;
    // }

    if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
    }

    if (document.body) {
        return document.body.clientWidth;
    }
};
